<template>
    <div
        :id="modalPrinter"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Printer Configuration</h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        @click="close"
                    ></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group">
                            <label for="selectPrinter">Printer Option</label>
                            <vue-multi-select
                                :options="printerOptions"
                                v-model="selectedOption"
                                :name="'printerOption'"
                                :validation="'required'"
                                :validationName="'Printer'"
                                @change="changePrintingOption"
                            ></vue-multi-select>
                        </div>

                        <!-- ini kalau  dia pilih print server-->
                        <div
                            class="form-group"
                            v-if="selectedOption == 'SERVER'"
                        >
                            <label for="selectPrinter">Select Server</label>
                            <vue-multi-select
                                :options="printServers"
                                v-model="selectedServer"
                                :name="'selectServer'"
                                :validation="'required'"
                                :validationName="'Server'"
                                :loading="isLoading"
                                :disabled="isLoading"
                            ></vue-multi-select>
                            <small style="color: red" v-if="connectingError">
                                Connecting failed, please chose another server.
                            </small>
                        </div>

                        <div class="form-group" v-if="isConnected && (isWebsocket || isRestData)">
                            <label for="selectPrinter"
                                >Select Server Printer</label
                            >
                            <vue-multi-select
                                v-if="isWebsocket"
                                :options="listPrinter"
                                v-model="selectedPrinter"
                                :name="'selectServerPrinter'"
                                :validation="'required'"
                                :validationName="'Printer'"
                            ></vue-multi-select>
                            <vue-multi-select
                                v-else
                                :options="listPrinter"
                                v-model="selectedPrinter"
                                :name="'selectServerPrinter'"
                                track-by="name" label="name"
                                :validation="'required'"
                                :validationName="'Printer'"
                                :loading="isFetchLoading"
                                :disabled="isFetchLoading"
                            ></vue-multi-select>
                            <small class=" kt-font-danger" v-if="emptyPrinter"
                                >Printer harus diisi.</small
                            >
                        </div>

                        <!-- jumlah print copy small ticket -->
                        <div class="form-group">
                            <label for="selectPrinter"
                                >Select Number of Copies</label
                            >
                            <vue-multi-select
                                :options="copies"
                                v-model="copiesNumber"
                                :name="'copies'"
                                :validation="'required'"
                                :validationName="'Copies'"
                                @change="change"
                            ></vue-multi-select>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-primary float-left"
                        :disabled="isDisabledTestPrinter"
                        @click="initPrinter"
                    >
                        Test Printer
                    </button>
                    <button type="button" class="btn btn-primary" :disabled="isDisabledPrinter" @click="save">
                        Save
                    </button>
                </div>
            </div>
        </div>
        <div class="printTicket" id="printTicket" ref="printTicket">
            <div
                style="text-align:center; margin: 0 3pt 0 3pt; font-family: sans-serif"
            >
                <div style="font-size: 12px;">MANDAYA HOSPITAL KARAWANG</div>
                <br />
                <div style="font-size: 12px;">
                    {{ detailPrint.displayName1 }}
                </div>
                <div style="font-size: 12px;">
                    {{ detailPrint.displayName2 }}
                </div>
                <div style="font-size: 12px;">{{ detailPrint.schedule }}</div>
                <div style="font-size: 55px;font-weight: bold;">
                    {{ detailPrint.queueNo }}
                </div>
                <br />
                <div style="font-size: 12px;">{{ detailPrint.date }}</div>
                <div style="font-size: 12px;">
                    Mohon menunggu nomor Anda dipanggil.
                </div>
                <br />
                <div class="content" style="display: flex;">
                    <div class="col img">
                        <vue-qrcode
                            :value="detailPrint.content"
                            :margin="qrProps.margin"
                            :width="qrProps.width"
                        />
                    </div>
                    <div class="col txt py-3" style="margin-left : 5px;">
                        <div style="font-size: 8px; text-align: left;">
                            Scan QR ini dengan Mandaya Health App
                        </div>
                        <br />
                        <div style="font-size: 8px; text-align: left;">
                            Download Aplikasinya di Google Play Store
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- tikcet kecil -->
        <div class="small-ticket d-none" id="small-ticket-sample">
            <div
                style="text-align:center; margin: 0 6pt 0 6pt; font-family: sans-serif"
            >
                <!-- <div style="font-size: 14px;">MANDAYA HOSPITAL KARAWANG</div>
                <br /> -->
                <div style="font-size: 12px;">
                    {{ detailPrint.displayName1 }}
                </div>
                <div style="font-size: 12px;">
                    {{ detailPrint.displayName2 }}
                </div>
                <div style="font-size: 12px;">{{ detailPrint.schedule }}</div>
                <div style="font-size: 55px;font-weight: bold;">
                    {{ detailPrint.queueNo }}
                </div>
                <br />
                <div style="font-size: 12px;">{{ detailPrint.date }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import qz from "qz-tray";
import VueQrcode from "vue-qrcode";
import vueMultiSelect from "../_select/VueMultiSelect";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const PrinterResourceRepository = RepositoryFactory.get("printerResource");
import PrinterNewServer from '@/services/printer'
import PrinterQzTray from '@/services/printerQzTray'
import axios from 'axios'

const mapperPrinter = (list) => {
    return list.map(_item => {
        return {
            name: _item.name,
            host: _item.host,
            port: _item.port
        }
    })
}
export default {
    data() {
        return {
            selectedPrinter: null,
            detailPrint: {
                displayName1: "Display Name 1",
                displayName2: "Display Name 2",
                queueNo: "0000",
                date: "HH:mm:ss",
                content: `{"content": "ne1CKh0e4owVf334HCjAUw==","type":"antrian"}`
            },
            qrProps: {
                margin: 0,
                width: 80
            },
            historyNumber: 0,
            copiesNumber: 0,
            copies: [0, 1, 2, 3, 4, 5],
            selectedOption: null,
            selectedServer: null,
            isConnected: false,
            isLoading: false,
            isDisabled: false,
            connectingError: false,
            emptyPrinter: false,
            isRestData: false,
            isWebsocket: false,
            isFetchLoading: false
        };
    },
    watch: {
        selectedOption(val) {
            if (val == "SERVER") {
                if (localStorage.selectedServer) {
                    this.selectedServer = localStorage.selectedServer;
                }
            } else if (val == "DIALOG") {
                this.selectedServer = null;
                this.selectServerPrinter = null
                if (qz.websocket.isActive()) {
                    qz.websocket.disconnect();
                    this.isConnected = false;
                }
            }
        },
        selectedServer(val) {
            let vx = this;
            // this.getListPrinterServer()
            this.isWebsocket = helpers.isCheckProtocol(val, 'QZ')
            this.isRestData = helpers.isCheckProtocol(val, 'REST')
            if(val) {
                this.setListPrinter([])
            }
            if (val && this.isWebsocket) {
                PrinterQzTray.openConnectionQzTray(val)
                    .then(() => {
                        vx.selectedPrinter = null;
                        vx.isLoading = true;
                        vx.connectToServerPrinter();
                        vx.connectingError = false
                    })
                    .catch(() => {
                        vx.connectingError = true
                        vx.isConnected = false
                        vx.selectedPrinter = null
                    })
            } else if (val && this.isRestData) {
                this.isConnected = true
                if (qz.websocket.isActive()) {
                    qz.websocket.disconnect();
                }
                this.isLoading = true;
                this.getListPrinterServer(val)
            }
        }
    },
    components: {
        vueMultiSelect,
        VueQrcode
    },
    model: {
        prop: "value",
        event: "change"
    },
    props: {
        modalPrinter: { type: String, default: "modalPrinter" },
        payload: { type: Object }
    },
    computed: {
        isDisabledPrinter() {
            return !(this.selectedPrinter) && this.selectOption == 'SERVER'  
        },
        isDisabledTestPrinter() {
            return (this.selectOption == 'DIALOG' || !(this.selectedPrinter))
        },
        ...mapState("CallerStore", {
            listPrinter: state => state.listPrinter,
            defaultPrinter: state => state.defaultPrinter,
            settingPrinter: state => state.settingPrinter,
            printServers: state => state.printServers,
            printerOptions: state => state.printerOptions,
            printingOption: state => state.printingOption,
            listProtocol: state => state.printProtocol
        }),
        selectOption: {
            get() {
                return this.printingOption;
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        ...mapMutations({
            setShowModalPrinter: "CallerStore/SET_SHOW_MODAL_PRINTER",
            setListPrinter: "CallerStore/SET_LIST_PRINTER",
            setDefaultPrinter: "CallerStore/SET_DEFAULT_PRINTER",
            setLoading: "CallerStore/SET_LOADING",
            setSettingPrinter: "CallerStore/SET_SETTING_PRINTER",
            setPrintingOption: "CallerStore/SET_PRINTING_OPTION",
            setPrintServers: "CallerStore/SET_PRINT_SERVERS",
            setTenantPrinter: "CallerStore/SET_TENANT_PRINTER",
            setListProtocol: 'CallerStore/SET_PROTOCOL_PRINTER'
        }),
        getListPrinterServer(url) {
            this.isFetchLoading = true
            // axios.get(url + '/msqueue/api/v1/printer-networks')
            axios.get(`${url}${process.env.VUE_APP_GET_PRINTER}`)
                .then(_item => {
                    this.isFetchLoading = false
                    // localStorage.selectedServer = this.selectedServer
                    this.isLoading = false;
                    this.isConnected = true;
                    this.connectingError = false;
                   const data = mapperPrinter(_item.data)
                   this.setListPrinter(data);

                })
                .catch(() => {
                    this.isFetchLoading = false
                    this.isLoading = false;
                    this.connectingError = true;
                })
        },
        findPrinter() {
            let vx = this;
            if (localStorage.listPrinter) {
                let printerList = JSON.parse(localStorage.listPrinter);
                vx.setListPrinter(printerList);
            } else {
                qz.printers.find().then(function(data) {
                    if (data) {
                        if (!data.includes(vx.defaultPrinter)) {
                            localStorage.removeItem("defaultPrinter");
                        }
                        vx.setListPrinter(data);
                        if (localStorage.defaultPrinter != null) {
                            vx.selectedPrinter = localStorage.defaultPrinter;
                        }
                    }
                });
            }
        },
        async connectToServerPrinter() {
            // disconnect dari printer yang aktif sebelumnya
            if (qz.websocket.isActive()) {
                await qz.websocket.disconnect();
                this.isConnected = false;
            }
            const options = {
                host: `${this.selectedServer}`,
                port: {
                    secure: [8181]
                },
                usingSecure: true
            };

            // reconnect setelah mengubah opsi
            await qz.websocket
                .connect(options)
                .then(() => {
                    // localStorage.selectedServer = this.selectedServer;
                    this.isLoading = false;
                    this.isConnected = true;
                    this.connectingError = false;
                    this.findPrinter();
                })
                .catch(() => {
                    this.isConnected = false;
                    this.isLoading = false;
                    this.connectingError = true;
                });
        },
        initPrinter() {
            if (this.isRestData) {
                this.testPrinter(0, this.selectedServer, this.selectedPrinter)
            } else if (this.isWebsocket) {
                this.testPrint()
            }
        },
        async testPrint() {
            let vx = this;
            let config = qz.configs.create(vx.selectedPrinter);
            const printDiv = this.$refs.printTicket.outerHTML;
            var data = [
                {
                    type: "pixel",
                    format: "html",
                    flavor: "plain", // or 'plain' if the data is raw HTML
                    data: `${printDiv}`
                }
            ];
            await qz.print(config, data).catch(function(e) {
                console.error(e);
            });
            if (localStorage.copiesNumber) {
                var data = [
                    {
                        type: "pixel",
                        format: "html",
                        flavor: "plain", // or 'plain' if the data is raw HTML
                        data: `${
                            document.getElementById("small-ticket-sample")
                                .outerHTML
                        }`
                    }
                ];
                let counter = 0;
                let maxNumber =
                localStorage.copiesNumber < 5
                        ? localStorage.copiesNumber
                        : 5;
                while (counter != maxNumber) {
                    await qz.print(config, data).catch(function(e) {
                        console.error(e);
                    });
                    counter++;
                }
            }
        },
        async testPrinter (i, url, selectPrinter) {
            let index = i
            const printClass = new PrinterNewServer()
            
            const timeUtc = new Date().toISOString()
            const listModel = {
                host: selectPrinter.host,
                port: selectPrinter.port,

            }
            const key = printClass.keyUniquePrinter(listModel.host, listModel.port, process.env.VUE_APP_KEY_PRINTER, timeUtc)
            const modelPrint = printClass.printServerTest()
            const body = {
                address: listModel.host,
                port: listModel.port,
                timeStamp: timeUtc,
                key,
                data: modelPrint
            }
            
            // axios.post('http://dapi.cd.local/msprinter/print-data/network', body)
            axios.post(`${url}${process.env.VUE_APP_POST_PRINTER}`, body)
                .then(_item => {
                    const copies = this.copiesNumber
                    // const numberCopies = Number(localStorage.copiesNumber)
                    let maxNumber =
                    copies < 5
                    ? copies
                    : 5;
                    if(index < maxNumber) {
                        index++
                        this.testPrinter(index, url, selectPrinter)
                    }
                })

        },
        change(val) {
            localStorage.copiesNumber = val;
        },
        save() {
            if (this.selectedOption == "DIALOG") {
                localStorage.copiesNumber = this.copiesNumber;
                localStorage.printingOption = 'DIALOG'
                $("#" + this.modalPrinter).modal("hide");
                localStorage.removeItem('selectedServer')
                localStorage.removeItem('defaultPrinter')
                localStorage.removeItem('selectedProtocol')
                this.setShowModalPrinter(false);
                if (!this.settingPrinter) {
                    this.setSettingPrinter(false);
                    this.payload.printD = true;
                    if (this.payload.printSelected) {
                        this.$parent.printSelectedQueue(this.payload);
                    } else {
                        this.$emit("print", this.payload);
                    }
                }
            } else if (this.selectedOption == "SERVER") {
                localStorage.printingOption = 'SERVER'
                if (this.selectedServer) {
                    localStorage.selectedServer = this.selectedServer
                    if (this.selectedPrinter) {
                        const selectedPrinter = typeof this.selectedPrinter == 'object' ? JSON.stringify(this.selectedPrinter) : this.selectedPrinter
                        this.setDefaultPrinter(selectedPrinter)
                        localStorage.copiesNumber = this.copiesNumber;
                        localStorage.selectedProtocol = helpers.selectedProtol(this.selectedServer, this.printServers, this.listProtocol)
                        $("#" + this.modalPrinter).modal("hide");
                        this.setShowModalPrinter(false);
                        this.payload.printD = false;
                        if (!this.settingPrinter) {
                            this.setSettingPrinter(true);
                            if (this.payload.printSelected) {
                                this.$parent.printSelectedQueue(this.payload);
                            } else {
                                this.$emit("print", this.payload);
                            }
                        }
                    } else {
                        this.emptyPrinter = true;
                    }
                }
            }
        },
        close() {
            $("#" + this.modalPrinter).modal("hide");
            localStorage.copiesNumber = this.historyNumber;
            this.setLoading(false);
            this.setShowModalPrinter(false);
        },
        async getServerList(){
            const { data } = await PrinterResourceRepository.getBackOfficeEnv()
            this.setPrintServers(data.printingService.printServers)
            this.setTenantPrinter(data.printingService.tenantPrinter)
            this.setListProtocol(data.printingService.protocol)
        },
        changePrintingOption(val) {
            this.setPrintingOption(val);
            // this.selectedPrinter = null;
        },
    },
    async destroyed() {
        if (qz.websocket.isActive()) {
                await qz.websocket.disconnect();
                this.isConnected = false;
        }
    },
    mounted() {
        $("#" + this.modalPrinter).modal();
        this.getServerList();
        if (localStorage.selectedServer) {
            this.selectedServer = localStorage.selectedServer;
        } 
        if (localStorage.copiesNumber) {
            this.historyNumber = localStorage.copiesNumber;
            this.copiesNumber = localStorage.copiesNumber;
        }
        if (localStorage.printingOption) {
            this.selectedOption = localStorage.printingOption;
        }

        if (localStorage.defaultPrinter) {
                let parsePrinter
                try {
                    parsePrinter = JSON.parse(localStorage.defaultPrinter)   
                } catch {
                    parsePrinter = localStorage.defaultPrinter
                }
                this.selectedPrinter = parsePrinter
            }
    }
};
</script>

<style></style>
